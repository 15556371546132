import React from "react"
import Seo from "../components/SEO"
import styled from "styled-components"
import { Link } from "gatsby"
import Button from "../components/Button"


const Placeholder = styled.div`
  height: 10vh;
`

const AboutPage = () => (
  <>
    <Seo title="About" description="About GreenFintech" />
    <h1>About</h1>
    <p>GreenFintech.org works with fintechs, financial institutions, governments and NGOs to build tomorrow’s green finance organisations and innovations.</p>
    <p>Our mission is to inspire and drive green fintech. To use advanced AI, blockchain, IoT and cloud technologies to do good. Creating partnerships and to deliver collaborative green and sustainable fintech initiatives.</p>
    <p>We have detailed knowledge and an extensive international network within green, climate and sustainable fintech.</p>
    <p>GreenFintech.org is founded in Amsterdam, The Netherlands and transformed into a remote organisation to keep our carbon footprint minimal and to enjoy nature.</p>
    <Placeholder></Placeholder>
    <Link href="/contact">
          <Button className="btn-link" to="/contact" text="Start our conversation" />
    </Link>
    <Placeholder></Placeholder>
  </>
)

export default AboutPage
